import React from "react"
import Header from "../components/header";
import Footer from "../components/footer";
import TaskSearch from "../components/taskSearch";
import homeStyles from "./index.module.css";
import groceriesImage from '../images/groceries.svg';
import dogImage from '../images/dog.svg';
import rakeImage from '../images/rake.svg';
import callIcon from '../images/call-icon.svg';

export default () => (
  <div>
    <div className={homeStyles.container}>
      <Header />
      <section className={homeStyles.banner}>
        <h1 className={homeStyles.h1}>Help those who need it most.</h1>
      </section>
      <section className={homeStyles.section}>
        <TaskSearch/>
      </section>
    </div>
    <section className={homeStyles.section}>
      <h1 className={homeStyles.h1}>Do you need assistance?</h1>
      <p className={homeStyles.p}>Our toll-free hotline makes it easy to securely request and recieve help for a variety of tasks.</p>
      <div className={homeStyles.card}>
        <img src={groceriesImage} alt="groceries" />
        <h2>Grocery Pickup</h2>
      </div>
      <div className={homeStyles.card}>
        <img src={dogImage} alt="dog walking" />
        <h2>Dog Walking</h2>
      </div>
      <div className={homeStyles.card}>
        <img style={{marginTop: '-8em'}} src={rakeImage} alt="yard work" />
        <h2>Yard Work</h2>
      </div>
      <p className={homeStyles.p}>If you or anyone you know could use assistance, don't hesitate to call.</p>
    </section>
    <section style={{paddingBottom: '4em'}} className={homeStyles.section}>
      <h1 className={homeStyles.h1}>Ready to get started?</h1>
      <a className={homeStyles.callBtn} href="tel:18559082757">Call 1-855-90-TASKS <img src={callIcon} alt="call"/></a>
    </section>
    <Footer />
  </div>
)
