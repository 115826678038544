import React from 'react'
import footerStyles from './footer.module.css'

export default () => {
  return  (
    <footer className={footerStyles.footer}>
      &copy; 2020 Turner Houghton. For any technical problems, email <a rel="noopener noreferrer" target="_blank" href="mailto:t.q.houghton@gmail.com">t.q.houghton@gmail.com</a>
    </footer>
  )
}
