import React, { useState, useEffect } from "react"
import Select from "react-select";
import axios from "axios";
import styles from "./taskSearch.module.css";
import { navigate } from "gatsby"
import searchSvg from '../images/search.svg';

const customStylesDefault = {
  borderWidth: 0,
  boxShadow: '0px 15px 14px rgba(0, 66, 134, 0.15)'
}

const getBorderRadius = (type) => {
  switch (type) {
    case 'state':
      return '2em 0 0 2em'
    case 'city':
      return '0 2em 2em 0'
    default:
      return ''
  }
}

const customStyles = (type) => ({
  control: (provided) => ({
    ...provided,
    ...customStylesDefault,
    borderRadius: getBorderRadius(type)
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: () => ({
    display: 'none'
  })
})

const customStylesState = customStyles('state');
const customStylesCity = customStyles('city');

export default () => {
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [zipCode, setZipCode] = useState('');

  const getCities = async (selectedState) => {
    if (!selectedState) {
      console.log('no selected state');
      return;
    }
    console.log('selectedState is: ', selectedState);
    const {data} = await axios.get('https://api.communitytasks.org/api/cities', {
      params: {
        state: selectedState.value
      }
    });

    setCities(data.items.map(city => ({value: city, label: city})));
  }

  const onStateSelect = option => {
    setSelectedState(option);
    getCities(option);
  }

  const getStates = async () => {
    const {data} = await axios.get('https://api.communitytasks.org/api/states');
    const selectableStates = data.items.map(({name, abbr}) => ({
      value: abbr,
      label: abbr
    }));

    setStates(selectableStates);
  }

  const constructTasksLink = () => {
    let searchStr
    if (zipCode) {
      const searchParams = new URLSearchParams({
        ...zipCode && {zipCode}
      })

      searchStr = searchParams.toString()
    } else {
      const searchParams = new URLSearchParams({
        ...selectedState && {state: selectedState.value},
        ...selectedCity && {city: selectedCity.value}
      })
  
      searchStr = searchParams.toString()
    }
    
    return '/tasks/' + (searchStr ? '?' + searchStr : '')
  }

  const isSearchEnabled = () => {
    const isEnabled = !!((selectedState && selectedCity) || (zipCode));
    return isEnabled;
  }

  const navigateToTasks = () => {
    navigate(constructTasksLink())
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {getStates()}, [])
  return  (
    <div>
      <div className={styles.form}>
        <h4 className={styles.h4}>Search By</h4>
        <div className={styles.selectContainer}>
          <Select
            styles={customStylesState}
            value={selectedState}
            options={states}
            onChange={onStateSelect}
            placeholder="State"
          />
          <Select
            styles={customStylesCity}
            value={selectedCity}
            options={cities}
            onChange={option => setSelectedCity(option)}
            placeholder="City"
          />
          <button className={styles.searchBtn} disabled={!isSearchEnabled()} onClick={navigateToTasks}>
            <img src={searchSvg} alt="search" />
          </button>
        </div>
        <h4 className={styles.h4}>Or by</h4>
        <div className={styles.zipCodeContainer}>
          <input type="text" className={styles.zipCodeInput} placeholder="Zip Code" value={zipCode} onChange={event => setZipCode(event.target.value)}></input>
          <button className={styles.searchBtn} disabled={!isSearchEnabled()} onClick={navigateToTasks}>
            <img src={searchSvg} alt="search" />
          </button>
        </div>
      </div>
    </div>
  )
}
